





// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { LSS } from '@core/services';
import { FlowService } from '@services/flow.service';
import Vue from 'vue';
import { API_ORY_URL } from '@config'
import { SignUpService } from '@services/signup.service';
import { decodeToken } from '@shared/utils/commom.util'
import { LoginService } from '@services/login.service';

export default Vue.extend({
  name: 'LoginProxy',
  methods: {
    async getUserDetails(UserID) {
      const token = await LoginService.getUserDetails({
        UserID: UserID,
        SessId: null
      });
      const user = decodeToken(token);
      //console.log(user);
      if(!LSS.adminToken) LSS.token = token
      this.$root.$emit('userNameFetched', user);
      return user;
    }
  },

  async beforeMount() {
    //console.log('v-updating-bef');
    const id = this.$route.query.id ? this.$route.query.id : '';
    const token = this.$route.query.token ? this.$route.query.token : '';
    const atoken = this.$route.query.at ? this.$route.query.at : '';
    if (id && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(id) && token) {
      try {
        const vupdate = await SignUpService.updateVerified({
          Email: id,
          Password: token,
        });

        LSS.set("verified", true);
      } catch (error) {
        //console.log(error);
      }
    }
    if (id && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(id) && atoken) {
      try {
        const aupdate = await SignUpService.updateAccepted({
          Email: id,
          Password: atoken,
        });

        LSS.set("joinedTeam", true);
      } catch (error) {
        //console.log(error);
      }
    }
  },

  async mounted () {
    const id = this.$route.query.id ? this.$route.query.id : '';
    const token = this.$route.query.token ? this.$route.query.token : '';
    const atoken = this.$route.query.at ? this.$route.query.at : '';
    if (id && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(id) && token) {
      try {
        const vupdate = await SignUpService.updateVerified({
          Email: id,
          Password: token,
        });

        LSS.set("verified", true);
        //console.log(vupdate);
      } catch (error) {
        //console.log(error);
      }
    }
    if (id && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(id) && atoken) {
      try {
        const aupdate = await SignUpService.updateAccepted({
          Email: id,
          Password: atoken,
        });

        LSS.set("joinedTeam", true);
      } catch (error) {
        //console.log(error);
      }
    }
    // to allow user to continue session after closing tab directly without logout
    // if(LSS.token && LSS.sessId){
    //   return this.$router.push('/overview');
    // }else{
    //   window.location.href = API_ORY_URL + '/self-service/login/browser'
    // }


    // continue the session if user tries to login again
    if(LSS.token && LSS.sessId){
       FlowService.whoIamI()
      .then(async (res) => {
       if(res.error?.status == 'Unauthorized'){ // LSS-1, ory-0, goto login
          LSS.clear();
          window.location.href = API_ORY_URL + 'self-service/login/browser'
       }else{ // LSS-1, ory-1, goto overview
          const res = await FlowService.fetchFlow(this.flow, 'logout')
          .catch((err) => {
            console.log("LoginProxy fetchFlow error ", err)
          });
          Vue.prototype.$out_url = res?.logout_url || ''
          // console.log(Vue.prototype.$out_url)
          const u = await this.getUserDetails(decodeToken(LSS.token).UserId).catch(e => {
          alert('Unable to process. Please try later')
          LSS.clear();
          window.location.href = Vue.prototype.$out_url
          });
          if(!u){
            alert('Unable to process. Please try later')
            LSS.clear();
            window.location.href = Vue.prototype.$out_url
          }
          return this.$router.push('/overview');
      }
      })
      .catch((err) => {
       console.log(err)
      });
    }else{
      FlowService.whoIamI()
      .then(async (res) => {
      if(res.error?.status == 'Unauthorized'){ // LSS-0, ory-0, goto login
          LSS.clear(); // no need but just in case
          window.location.href = API_ORY_URL + 'self-service/login/browser'
      }else{ // LSS-0, ory-1, clear ory and goto login
          const res = await FlowService.fetchFlow(this.flow, 'logout')
          .catch((err) => {
            console.log("LoginProxy fetchFlow error ", err)
          });
          window.location.href = res?.logout_url || ''
        }
      })
      .catch((err) => {
        //console.log(err)
      });
    }



    // if tab is closed directly and dont want to allow user to continue session
    // if(LSS.token && LSS.sessId){
    //   LSS.clear();
    //   window.location.href = Vue.prototype.$out_url
    // }else{
    //   FlowService.whoIamI()
    //   .then((res) => {
    //     if(res.error?.status == 'Unauthorized'){
    //       window.location.href = API_ORY_URL + 'self-service/login/browser'
    //     }
    //     else{
    //       window.location.href = Vue.prototype.$out_url
    //     }
    //   })
    //   .catch((err) => {
    //     //console.log(err)
    //   });
    //   //window.location.href = API_ORY_URL + 'self-service/login/browser'
    // }
  }
});
